<template>
  <router-link v-if="link" :to="{name: link , hash: position}">
    <div :class="'view mb-3 ' + classes.div">
      <img
        :src="getImage(imgUrl)"
        :class="'img-fluid ' + classes.img"
      />
      <div class="mask flex-center">
        <p :class="'texto ' + classes.p">
          {{ texto }}
        </p>
      </div>
    </div>
  </router-link>

  <div v-else :class="'view mb-3 ' + classes.div">
    <img
      :src="getImage(imgUrl)"
      :class="'img-fluid ' + classes.img"
      alt="Image of ballons flying over canyons with mask pattern one."
    />
    <div class="mask flex-center">
      <p :class="'texto ' + classes.p">
        {{ texto }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    imgUrl: String,
    texto: String,
    classes: Object,
    link: String,
    position: String,
  },
  methods: {
    getImage(fileName) {
      return require("../assets/images/banners/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/constants.scss";

.view {
  position: relative;
  overflow: hidden;
  cursor: default;
}

.view img,
.view video {
  position: relative;
  display: block;
}

.view .mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
}

.img-fluid,
.modal-dialog.cascading-modal.modal-avatar .modal-header,
.video-fluid {
  max-width: 100%;
  height: auto;
}

.mask {
  background-attachment: fixed;
}

.texto {
  // color: #1b3281;
  color: $azul;
  width: 40%;
  align-self: flex-start;
  text-align: left;
  padding-left: 10%;
  padding-top: 10%;
}
.flex-center {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
}
</style>
