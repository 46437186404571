<template>
  <div class="container-fluid mb-3 bg-gris-claro cus-section">
    <div class="container d-flex inline py-3 justify-content-between">
      <div>
        <h5 class="font-weight-bolder">
          {{ title }}
        </h5>
        <p>
          {{ text }}
        </p>
        <p v-if="collapsedText" :class="{ oculto: isHidden }">
          {{ collapsedText }}
        </p>
      </div>
      <div v-if="link" class="d-flex align-self-center">
        <router-link class="info" :to="link">+ INFO</router-link>
      </div>
      <div v-if="collapsedText">
        <button @click="isHidden = !isHidden" class="info">{{ $t('section.verMas') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    title: String,
    text: String,
    link: String,
    collapsedText: String,
  },
  data() {
    return {
      isHidden: true,
    };
  },
};
</script>
