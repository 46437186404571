<template>
  <div id="app">
    <div class="container d-flex justify-content-between py-3">
      <div class="d-flex">
        <img
          src="@/assets/images/icono_ntr.png"
          alt=""
          width="35"
          height="35"
          class="d-inline-block align-text-top"
        />
      </div>
      <div class="d-flex align-items-center">
        <div class="d-flex multilang">
          <span
            @click="changeLang('es')"
            :class="{ selected: this.$i18n.locale === 'es' }"
            >Español</span
          >
          <span class="mx-1 selected">|</span>
          <span
            @click="changeLang('en')"
            :class="{ selected: this.$i18n.locale === 'en' }"
            >English</span
          >
        </div>
        <img
          src="@/assets/images/logo_nutentar.png"
          alt=""
          width="116"
          height="35"
          class="d-inline-block align-text-top img-fluid"
        />
      </div>
    </div>

    <nav id="nav" class="navbar navbar-expand-lg">
      <div class="container text-uppercase">
        <button
          class="navbar-dark navbar-toggler"
          type="button"
          @click="show = !show"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          id="navbar"
          class="collapse navbar-collapse"
          :class="{ show: show }"
        >
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 menu">
            <li class="nav-item" @click="show = false">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'Home' }"
              >
                {{ $t("navbar.home") }}
              </router-link>
            </li>
            <li class="nav-item" @click="show = false">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'Productos' }"
              >
                {{ $t("navbar.productos") }}
              </router-link>
            </li>
            <!-- <li class="nav-item dropdown">
              <router-link
                class="nav-link dropdown-toggle"
                to="/productos"
                id="navbarDropdown"
              > PRODUCTOS </router-link>
              <ul class="dropdown-menu">
                <li>
                  <router-link class="dropdown-item" to="/productos/sustenta-vainilla" >ITEM 1</router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/productos/productoB">ITEM 2</router-link>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li>
                  <router-link class="dropdown-item" to="/productos">MAS ITEMS</router-link>
                </li>
              </ul>
            </li> -->
            <li class="nav-item" @click="show = false">
              <router-link class="nav-link" to="/vademecum">
                VADEMECUM
              </router-link>
            </li>
            <li class="nav-item" @click="show = false">
              <router-link class="nav-link" to="/videos"> VIDEOS </router-link>
            </li>
            <li class="nav-item" @click="show = false">
              <router-link class="nav-link" to="/cuidando-tu-salud">
                {{ $t("navbar.cuidandoTuSalud") }}
              </router-link>
            </li>
            <li class="nav-item" @click="show = false">
              <router-link class="nav-link" to="/faq">
                {{ $t("navbar.faq") }}
              </router-link>
            </li>
            <li class="nav-item nav-icons socials" @click="show = false">
              <a class="nav-link" href="https://ar.linkedin.com/company/nutentar" target="_blank">
                <i class="fa fa-linkedin"></i>
              </a>
              <a class="nav-link" href="https://www.facebook.com/Nutentar" target="_blank">
                <i class="fa fa-facebook"></i>
              </a>
              <a class="nav-link" href="https://instagram.com/nutentar.arg" target="_blank">
                <i class="fa fa-instagram"></i>
              </a>
              <a class="nav-link" href="https://listado.mercadolibre.com.ar/_CustId_1027198358?item_id=MLA1123613149&category_id=MLA8830&seller_id=1027198358&client=recoview-selleritems&recos_listing=true" target="_blank">
                <i class="fa fa-shopping-cart"></i>
              </a>
            </li>
          </ul>
          <!-- <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form> -->
        </div>
      </div>
    </nav>

    <router-view />

    <footer id="footer" class="footer mt-auto py-3 pt-5">
      <div class="container wrapper">
        <div class="box">
          <img
            src="@/assets/images/logo_nutentar_blanco.png"
            alt=""
          />
        </div>
        <div class="box d-flex flex-column">
          <router-link :to="{ name: 'Home' }">{{
            $t("footer.home")
          }}</router-link>
          <router-link :to="{ name: 'Productos', hash: '#top' }">{{
            $t("footer.productos")
          }}</router-link>
          <router-link
            :to="{ name: 'PoliticasDeCalidad', hash: '#politicas-de-calidad' }"
            >{{ $t("footer.politicasDeCalidad") }}</router-link
          >
          <router-link :to="{ name: 'CuidandoTuSalud' }">{{
            $t("footer.cuidandoTuSalud")
          }}</router-link>
        </div>
        <div class="box d-flex flex-column">
          <router-link
            :to="{ name: 'PoliticasDeCalidad', hash: '#quienes-somos' }"
            >{{ $t("footer.quienesSomos") }}</router-link
          >
          <router-link :to="{ name: 'Vademecum' }">Vademecum</router-link>
          <router-link :to="{ name: 'Faq' }">{{
            $t("footer.faq")
          }}</router-link>
          <router-link :to="{ name: 'Videos' }">Links / Videos</router-link>
        </div>
        <div class="box d-flex flex-column">
          <router-link :to="{ name: 'Contacto' }">{{
            $t("footer.contacto")
          }}</router-link>
          <span>nutentar@nutentar.com</span>
          <span>Vicente Lopez 226 - Moron CP (1708)</span>
          <span>Buenos Aires - Argentina</span>
          <span>{{ $t("footer.tel") }}</span>
        </div>
        <div class="box no-border d-flex">
          <a class="pe-2" href="https://ar.linkedin.com/company/nutentar" target="_blank">
            <i class="fa fa-2x fa-linkedin"></i>
          </a>
          <a class="pe-2" href="https://www.facebook.com/Nutentar" target="_blank">
            <i class="fa fa-2x fa-facebook"></i>
          </a>
          <a class="ps-2" href="https://instagram.com/nutentar.arg" target="_blank">
            <i class="fa fa-2x fa-instagram"></i>
          </a>
          </div>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=5491155291459&text=Consulta"
        class="float"
        target="_blank"
        v-if="this.$route.name === 'Home' || this.$route.name === 'Contacto'"
      >
        <i class="fa fa-whatsapp my-float"></i>
      </a>
    </footer>
  </div>
</template>

<script>
export default {
  name: "LangSwitcher",
  data() {
    return {
      selected: process.env.VUE_APP_I18N_LOCALE,
      show: false,
      disclamerAccepted: false,
    };
  },
  methods: {
    changeLang(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }

      localStorage.setItem('locale', locale)
    },
    callModal(disclamerAccepted) {
      if (!disclamerAccepted && this.$route.name === 'Home') {
        this.$swal({
          title: this.$t('home.disclaimer.titulo'),
          html: this.$t('home.disclaimer.mensaje'),
          showConfirmButton: true,
          showDenyButton: true,
          confirmButtonText: this.$t('home.disclaimer.aceptar'),
          DenyButtonText: this.$t('home.disclaimer.rechazar'),
          confirmButtonColor: "#55afe5",
          denyButtonColor: "#55afe5",
          focusConfirm: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: "swal-wide",
          showClass: {
            popup: 'animated fadeIn'
          }
        }).then((result) => {
          if (result.isDenied) {
            this.$swal({
              icon: "error",
              title: this.$t('home.disclaimer.error_titulo'),
              text: this.$t('home.disclaimer.debe_aceptar'),
              confirmButtonColor: "#55afe5",
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              showClass: {
                popup: 'animated headShake'
              }
            });
          } else {
            this.disclamerAccepted = true;
          }
        });
      }
    }
  },
  mounted() {
    this.callModal(this.disclamerAccepted);
  },
};
</script>

<style lang="scss">
@media (max-width: 425px) {
    .img-full {
        display: none !important;
    }

    .img-responsive {
        display: block !important;
    }
    
  .pl-mobile {
    padding-left: 20px !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "@/assets/scss/constants.scss";
@import "@/assets/scss/custom.scss";


.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.float:hover {
  color: #fff;
  opacity: 0.8;
}

.my-float {
  margin-top: 16px;
}

.multilang {
  padding-right: 1em;

  & span {
    // text-decoration: none !important;
    color: $celeste;
    font-size: 0.6rem !important;
    cursor: pointer;
  }

  .selected {
    color: $gris !important;
  }
}

.wrapper {
  display: flex;
  direction: row;
  align-items: center;
  justify-content: space-between;
}

.box {
  border-left: 1px solid #dee2e6 !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.box:first-of-type {
  border-left: none !important;
  text-align: center;
  img {
    max-width: 160px;
  }
}

.no-border {
  border: none !important;
}

.box:last-of-type {
  max-width: 250px;
}

@media (max-width: 425px) {
  .wrapper {
    width: 100%;
    display: block;
  }

  .box {
    // max-width: 200px;
    border-left: none !important;
    border-bottom: 1px solid #dee2e6 !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .box:first-of-type {
    // max-width: 160px;
    // margin-bottom: 2rem;
    border-left: none !important;
    border-bottom: none !important;
    img {
      min-width: 60%;
    }
  }

  .box:last-of-type {
    max-width: 100% !important;
  }
}

.navbar-brand {
  margin-right: 0 !important;
  // margin-left: 1rem !important;
}

.cart {
  padding: 4px 8px 8px 8px !important;

  & img {
    width: 35px;
  }
}

.swal-wide {
  width: 850px !important;
}

</style>