<template>
  <div class="container p-3">
    <div class="row row-cols-2 row-cols-md-5 g-4">
      <div class="col">
          <router-link :to="{name: 'Productos', hash: '#nutricion-clinica-adultos'}">
          <div class="card h-100">
            <h4 class="card-header d-flex align-items-center justify-content-center h-100">{{ $t('cardsModulos.nutricionClinicaAdultos') }}</h4>
              <div class="card-image">
                <img
                  src="@/assets/images/cards/cards-modulos-adultos.jpg"
                  class="card-img-top"
                  alt=""
                />
            </div>
          </div>
        </router-link>
      </div>
      <div class="col">
        <router-link :to="{name: 'Productos', hash: '#modulos-nutricionales'}">
        <div class="card h-100">
            <h4 class="card-header d-flex align-items-center justify-content-center h-100">{{ $t('cardsModulos.modulosNutricionales') }}</h4>  
            <div class="card-image">
              <img
                src="@/assets/images/cards/cards-modulos-modulos.jpg"
                class="card-img-top"
                alt=""
              />
          </div>
        </div>
        </router-link>
      </div>
      <div class="col">
        <router-link :to="{name: 'Productos', hash: '#nutricion-clinica-pediatricos'}">
        <div class="card h-100">
          <h4 class="card-header d-flex align-items-center justify-content-center h-100">{{ $t('cardsModulos.nutricionClinicaPediatricos') }}</h4>
          <div class="card-image">
            <img
              src="@/assets/images/cards/cards-modulos-pediatricos.jpg"
              class="card-img-top"
              alt=""
            />
          </div>
        </div>
        </router-link>
      </div>
      <div class="col">
        <router-link :to="{name: 'Productos', hash: '#dieta-cetogenica'}">
        <div class="card h-100">
            <h4 class="card-header d-flex align-items-center justify-content-center h-100">{{ $t('cardsModulos.dietaCetogenica') }}</h4>
            <div class="card-image">
              <img
                src="@/assets/images/cards/cards-modulos-cetogenica.jpg"
                class="card-img-top"
                alt=""
              />  
            </div>
        </div>
        </router-link>
      </div>
      <div class="col">
        <router-link :to="{name: 'Productos', hash: '#hangPack'}">
        <div class="card h-100">
            <h4 class="card-header d-flex align-items-center justify-content-center h-100">{{ $t('cardsModulos.hangPack') }}</h4>
            <div class="card-image">
              <img
                src="@/assets/images/cards/cards-modulos-hangpack.jpg"
                class="card-img-top"
                alt=""
              />
            </div>
        </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cards",
};
</script>

<style scoped lang="scss">
.card-header {
  // & h4{
    color: #fff;
    font-weight: 400;
    font-size: 0.9rem;
    text-align: center;
    // line-height: 1.5;
    // margin-inline-start: 0 em;
    // margin-inline-end: 0 em;
  // }
  background-image: url('../assets/images/cards/cards-modulos-fondo.jpg');
}
.card {
  cursor: pointer;
}

</style>
